.headerBox {
  display: flex;
  padding-top: 20px;
}

.arrowBox {
  display: flex;
  justify-content: center;
  color: white;
}

.dateBox {
  display: flex;
  justify-content: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 900;
  color: white;
}

.arrowleft {
  transform: rotate(180deg);
}

.currentPower {
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 800;
  color: white;
}

.Text body {
  background-color: #161719;
}



.chargeRow {
  height: 40px;
  /* margin: 18px; */
}

.charBar {
  width: 100%;
}

.custom-btn-width {
  width: inherit;
  margin-top: 10px;
}

.topRow {
  margin-top: 15px;
  margin-bottom: 15px;
}

.notificationText {
  color: white;
  font-size: 0.9rem;
  text-align: center;
  padding-top: 5px;
}

.chargeStatistics {
  /* color: white; */
  text-align: center;
  flex: 1;
  padding-right: 0;
  padding-left: 0;
}

.statisticsRow {
  padding-top: 5px;
  margin-bottom: -5px;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  animation: fadeIn 5s;
}

.statisticNumber {
  font-size: 0.9rem;
  color: #25c781;
}

.statisticIcon {
  fill: #25c781;
  padding-right: 3px;
}

.footerBox {
  height: 60px;
}

.chargePower {
  text-align: center;
  color: white;
  padding-top: 8px;
}

/* -----------------------------------------------------------Styling Nav Items ----------------------------------------------------------- */
.navItemRow {
  height: 60px;
}

.navColumnItem {
  align-items: center;
  display: flex;
}

.navColumnItem h4 {
  color: white;
  margin-bottom: 0;
  text-decoration: none !important;
}

.navItemBar {
  text-decoration: none;
}

.navItemIcon {
  height: 22px;
  color: white;
}

.alignItemsCenter {
  align-items: center;
  display: flex;
}

.justifyContentCenter {
  justify-content: center;
  display: flex;
}

.backIcon {
  rotate: 180deg;
}

.dasboardMainFigure {
  color: white;
  padding: 25px;
  font-size: 2.2rem;
}
